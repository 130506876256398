<div class="destaque-item">
      <div class="capa" *ngIf="data.tipo == 1" (click)="_click()">
            <figure>
                  <img [src]="data.imagem" [alt]="data.titulo" width="100%" />
            </figure>
      </div>
      <div class="capa" *ngIf="data.tipo == 2">  
            <iframe width="100%" height="200px" [src]="link" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
      <div class="content" (click)="_click()">
            <h3>{{data.titulo}}</h3>
            <article [innerHtml]="data.descricao | safeHtml"></article>
      </div>
</div>
