import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProdutoDestaquePorCategoriaComponent } from 'src/app/modulos/produto-destaque-por-categoria/produto-destaque-por-categoria.component';
import { PartialModule } from '../../partial/partial.module';
import { ProdutoItemSliderModule } from '../../produto/produto-item-slider/produto-item-slider.module';

@NgModule({
  declarations: [
    ProdutoDestaquePorCategoriaComponent
  ],
  exports: [
    ProdutoDestaquePorCategoriaComponent
  ],
  imports: [ 
    CommonModule,
    PartialModule,
    ProdutoItemSliderModule
  ]
})
export class ModuloProdutoDestaquePorCategoriaModule{}
