import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Modulo } from 'src/app/modulos/modulo/modulo.component';
import { ModuloLogoModule } from './modulo-logo/modulo-logo.module';
import { ModuloContatoModule } from './modulo-contato/modulo-contato.module';
import { ModuloRedeSocialModule } from './modulo-rede-social/modulo-rede-social.module';
import { ModuloNewsletterModule } from './modulo-newsletter/modulo-newsletter.module';
import { BannerSliderSlickComponent } from 'src/app/modulos/banner-slider-slick/banner-slider-slick.component';
import { ProdutoDestaqueOneComponent } from 'src/app/modulos/produto-destaque-one/produto-destaque-one.component';
import { ProdutoDestaqueTwoComponent } from 'src/app/modulos/produto-destaque-two/produto-destaque-two.component';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import { ProdutoPartialModule } from '../produto/produto-partial/produto-partial.module';
import { DestinoItemModule } from '../destinos/destino-item/destino-item.module';
import { PipeModule } from '../pipe/pipe.module';
import { EnderecoComponent } from 'src/app/modulos/endereco/endereco.component';
import { ModuloWhatsappModule } from './modulo-whatsapp/modulo-whatsapp.module';
import { ModuloFaleConoscoModule } from './modulo-fale-conosco/modulo-fale-conosco.module';
import { ModuloBlogModule } from './modulo-blog/modulo-blog.module';
import { ModuloHorariosModule } from './modulo-horarios/modulo-horarios.module';
import { ModuloDestinosModule } from './modulo-destinos/modulo-destinos.module';
import { ModuloAtendimentoPersonalizadoModule } from './modulo-atendimento-personalizado/modulo-atendimento-personalizado.module';
import { ModuloProdutoDestaquePorCategoriaModule } from './modulo-produto-destaque-por-categoria/modulo-produto-destaque-por-categoria.module';
import { ModuloDepoimentoModule } from './modulo-depoimento/modulo-depoimento.module';
import { ModuloLogoFooterModule } from './modulo-logo-footer/modulo-logo-footer.module';
import { DestaquesComponent } from 'src/app/modulos/destaques/destaques.component';
import { DestaqueItemComponent } from 'src/app/components/destaque/destaque-item/destaque-item.component';

@NgModule({
  declarations: [
    Modulo,
    BannerSliderSlickComponent,
    ProdutoDestaqueOneComponent,
    ProdutoDestaqueTwoComponent,
    EnderecoComponent,
    DestaquesComponent,
    DestaqueItemComponent
  ],
  exports:[
    Modulo,
    BannerSliderSlickComponent,
    EnderecoComponent,
    DestaqueItemComponent
  ],
  imports: [
    ModuloDestinosModule,
    ModuloFaleConoscoModule,
    ModuloWhatsappModule,  
    DestinoItemModule,
    ProdutoPartialModule,
    LazyLoadImageModule,
    ModuloLogoModule, 
    ModuloContatoModule,
    ModuloRedeSocialModule,
    ModuloNewsletterModule, 
    ModuloBlogModule,
    ModuloHorariosModule,
    CommonModule,
    PipeModule,
    ModuloAtendimentoPersonalizadoModule,
    ModuloProdutoDestaquePorCategoriaModule,
    ModuloDepoimentoModule,
    ModuloLogoFooterModule
  ]
})
export class ModuloModule{}
