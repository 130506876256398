import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PartialModule } from '../../partial/partial.module';
import { ModuloDestinosComponent } from 'src/app/modulos/modulo-destinos/modulo-destinos.component';
import { DestinoItemModule } from '../../destinos/destino-item/destino-item.module';

@NgModule({
  declarations: [
    ModuloDestinosComponent
  ],
  exports: [ 
    ModuloDestinosComponent
  ],
  imports: [
    CommonModule,
    PartialModule,
    DestinoItemModule 
  ]
})
export class ModuloDestinosModule{} 
