<div class="module-newsletter">
    <div class="container">    
        <div class="content">
            <div class="row">
                <div class="col-md-6">
                    <figure>
                        <img src="/images/newsletter/bg.jpg" width="100%" />
                    </figure>
                </div>
                <div class="col-md-6">  
                    <form class="form-custom" (submit)="send()">
                        <h3>{{data.titulo}}</h3>
                        <h4>{{data.subtitulo}}</h4>
                        <div class="content-form">
                            <div class="content-input">  
                                <div class="item item-input wow fadeIn" data-wow-delay="0.3s">
                                    <input type="text" [(ngModel)]="dataItem.nome" name="nome" placeholder="Informe seu nome" />
                                </div>    
                                <div class="item item-input wow fadeIn" data-wow-delay="0.3s">
                                    <input type="text" [(ngModel)]="dataItem.email" name="email" placeholder="Informe seu email" />    
                                </div> 
                            </div>  
                            <div class="content-button">  
                                <button class="btn-three" data-wow-delay="0.5s"> 
                                    <span>Me cadastrar</span>
                                </button>
                            </div> 
                        </div>  
                    </form> 
                </div>
            </div>   
        </div>
    </div>
</div>    
<loader *ngIf="loader"></loader>
