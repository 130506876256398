import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProdutoItemSliderComponent } from 'src/app/components/produto/produto-item-slider/produto-item-slider.component';
import { PartialModule } from '../../partial/partial.module';
import { ProdutoPartialModule } from '../produto-partial/produto-partial.module';

@NgModule({
  declarations: [
    ProdutoItemSliderComponent
  ],
  exports: [
    ProdutoItemSliderComponent
  ],
  imports: [
    CommonModule,
    ProdutoPartialModule,
    PartialModule
  ]
})
export class ProdutoItemSliderModule{}
