<div class="menu-floating">
      <ul>
          <li>
              <div class="icon tooltip" (click)="_route('/carrinho')">
                <i class="fa-sharp fa-solid fa-bag-shopping"></i>
                <span class="tooltiptext tooltip-left">Minhas Compras</span>
              </div>    
          </li>
          <li>  
              <div class="icon tooltip" (click)="_route('/minha-conta/favoritos')">
                <i class="fa-solid fa-heart"></i>
                <span class="tooltiptext tooltip-left">Meus Favoritos</span>  
              </div>      
          </li>
          <li> 
              <div class="icon tooltip" (click)="_route('/minha-conta/perfil')">
                <i class="fa-solid fa-user"></i>
                <span class="tooltiptext tooltip-left">Minha Conta</span>  
              </div>      
          </li>
      </ul>    
</div>