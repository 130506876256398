<div [ngSwitch]="template">
  <section *ngSwitchCase="'home'" class="modulo modulo-contato-home default-home" itemscope itemtype="https://schema.org/Organization">
    <div class="container">   
      <h2 class="wow animTypeWriter" data-delay="0.5s">Nossos canais de atendimento</h2>
      <div class="list-contato">
        <div class="item wow fadeIn" data-wow-delay="0.5s" (click)="openWhatsApp()">
          <img lazyLoad="/images/template/icons/whatsapp-2.svg" defaultImage="/images/pixel/bg.png" width="50px" height="50px" alt="WhatsApp" />
          <div class="text">
            <strong>WhatsApp</strong><br>
            <span class="value">{{data.params.whatsapp}}</span>
          </div>  
        </div>
        <div class="item wow fadeIn" data-wow-delay="0.7s">
          <img lazyLoad="/images/template/icons/telefone.svg" defaultImage="/images/pixel/bg.png" width="50px" height="50px" alt="Telefone" />
          <div class="text">
            <strong>Telefone</strong><br>
            <a href="tel:{{data.params.celular}}" class="value" title="Telefone">{{data.params.celular}}</a>  
          </div>  
        </div> 
        <div class="item wow fadeIn" data-wow-delay="0.8s">
          <img lazyLoad="/images/template/icons/email.svg" defaultImage="/images/pixel/bg.png" width="50px" height="50px" alt="Email" />
          <div class="text">
            <strong>Email</strong><br>
            <a href="mailto:{{data.params.email}}" class="value" title="Email">{{data.params.email}}</a>
          </div>  
        </div> 
        <div class="item wow fadeIn" data-wow-delay="1s"> 
          <img lazyLoad="/images/template/icons/instagram-3.svg" defaultImage="/images/pixel/bg.png" width="50px" height="50px" alt="Instagram" />
          <div class="text">
            <strong>Instagram</strong><br>
            <a href="{{data.params.instagram_link}}" target="_blank" class="value" title="Instagram">{{data.params.instagram_conta}}</a>
          </div>  
        </div>   
      </div>
      <div class="bg-contato"></div>
    </div>
  </section>
  <section *ngSwitchCase="'footer'" class="default-footer">
    <ul class="list-contact">
      <li (click)="openWhatsApp()">
          <div class="icon">
              <i class="fa-brands fa-whatsapp"></i>
          </div>
          <div class="text">
              <span>+351 {{data.params.whatsapp}}</span>
          </div>    
      </li>
      <li (click)="openTel()">
          <div class="icon">
              <i class="fa-solid fa-phone"></i>
          </div>
          <div class="text">
              <span>+351 {{data.params.telefone}}</span>
          </div>    
      </li>
      <li (click)="openEmail()">
          <div class="icon">
              <i class="fa-solid fa-envelope"></i>
          </div>
          <div class="text">
            <span>{{data.params.email}}</span>
          </div>    
      </li>
  </ul>
  </section>
  <div *ngSwitchCase="'email'">
    <div class="content-item" (click)="toEmail()">  
      <h3>Nosso <br>E-mail</h3>
      <div class="contato-item">
          <div class="icon bg-bege">
            <i class="fa-solid fa-envelope"></i>
          </div>   
          <div class="content">
            <span>{{data.params.email}}</span>
          </div> 
      </div>
    </div>
  </div>
  <div *ngSwitchCase="'telefone'" class="default-email">
    <h3 class="modulo-title">Telefone:</h3> 
    <a href="tel:{{data.params.celular}}" title="Telefone">{{data.params.celular}}</a>
  </div>
  <div *ngSwitchCase="'page-contato-telefone'" class="default-list page-contato-telefone">
    <div class="icon bg-orange">
      <span class="material-icons color-white">
        phone_enabled
      </span>
    </div> 
    <div class="content"> 
      <span class="title">Ligue Agora</span>
      <a href="tel:{{data.params.celular}}" title="Telefone">{{data.params.celular}}</a>
    </div>
  </div>  
  <div *ngSwitchCase="'page-contato-email'">
    <div class="card-item card-item-contact wow slideInDown" data-wow-delay="0.5s">
      <div class="card-item-header">
          <div class="icon"> 
              <i class="fa-solid fa-envelope"></i>
          </div>
          <h3>{{data.params.email}}</h3>    
      </div>
    </div>   
  </div> 
  <div *ngSwitchCase="'page-contato'">
    <div class="card-item card-item-contact" (click)="toEmail()">
      <div class="card-item-header">
          <div class="icon">
              <i class="fa-solid fa-envelope"></i>
          </div>
          <h3>{{data.params.email}}</h3>    
      </div>
    </div>
  </div>
  <div *ngSwitchCase="'contact-whatsapp'">
    <div class="box-sombra card-item-contact wow slideInUp" data-wow-delay="0.4s" (click)="openWhatsApp()">
      <div class="card-item-header">
          <div class="icon">
              <i class="fa-brands fa-whatsapp"></i>
          </div> 
          <div class="text">
              <span>WhatsApp:</span>
              <h3>{{data.params.whatsapp}}</h3>  
          </div>  
      </div>
    </div>
  </div>
  <div *ngSwitchCase="'contact-email'">
    <div class="box-sombra card-item-contact wow slideInUp" data-wow-delay="0.4s" (click)="openEmail()">
      <div class="card-item-header">
          <div class="icon"> 
              <i class="fa-solid fa-envelope"></i>
          </div> 
          <div class="text">
              <span>E-mail:</span>
              <h3>{{data.params.email}}</h3>  
          </div>  
      </div>
    </div>
  </div>
  <div *ngSwitchCase="'contact-telefone'">
    <div class="box-sombra card-item-contact wow slideInUp" data-wow-delay="0.4s" (click)="openTel()">
      <div class="card-item-header">
          <div class="icon">
              <i class="fa-solid fa-phone"></i>
          </div> 
          <div class="text"> 
              <span>Telefone:</span>
              <h3>{{data.params.telefone}}</h3>  
          </div>  
      </div>
    </div> 
  </div>
</div>  


