<a routerlink="/" *ngIf="template == null" (click)="_click()">
  <figure class="logo {{classCustom}}"> 
    <img width="120px" [src]="logo" alt="Logo" />
  </figure>
</a> 
<a routerlink="/" *ngIf="template == 'white'" (click)="_click()">
  <figure class="logo {{classCustom}}"> 
    <img width="120px" [src]="logo" alt="Logo" />
  </figure>
</a> 
<a routerlink="/" *ngIf="template == 'footer'" (click)="_click()">
  <figure class="logo {{classCustom}} logo-footer"> 
    <img width="200px" [src]="logo" alt="Logo" />
  </figure>
</a>  


  