import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AtendimentoPersonalizadoComponent } from 'src/app/modulos/atendimento-personalizado/atendimento-personalizado.component';
import { PartialModule } from '../../partial/partial.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoaderModule } from '../../loader/loader/loader.module';
import { DirectivesModule } from '../../directives/directives/directives.module';

@NgModule({
  declarations: [
    AtendimentoPersonalizadoComponent
  ],
  exports: [
    AtendimentoPersonalizadoComponent
  ],
  imports: [
    CommonModule,
    PartialModule,
    ReactiveFormsModule,
    FormsModule,
    LoaderModule,
    DirectivesModule 
  ]
})
export class ModuloAtendimentoPersonalizadoModule{}
