<div class="module module-atendimento-personalizado" [ngStyle]="{'background-image': 'url('+getImg()+')'}"> 
      <div class="module-content">
          <div class="container">
              <div class="row">
                  <div class="col-md-4">
                      <div class="module-title"> 
                          <h2 class="wow slideInUp" data-wow-delay="0.2s">{{data.titulo}}</h2>
                          <h3 class="wow slideInLeft" data-wow-delay="0.4s">
                              {{data.subtitulo}} 
                          </h3>
                          <h3 class="wow slideInDown" data-wow-delay="0.5s">
                              {{data.params.texto}}
                          </h3>
                      </div>
                  </div>
                  <div class="col-md-8">   
                      <div class="content-form wow slideInRight" data-wow-delay="0.8s">
                          <form class="form form-custom" (submit)="send()">
                              <div class="form-group">
                                  <label>Informe seu nome:</label>
                                  <input type="text" [(ngModel)]="dataItem.nome" name="name" />
                              </div> 
                              <div class="form-group">
                                  <label>Informe seu e-mail:</label>
                                  <input type="text" [(ngModel)]="dataItem.email" name="email" />
                              </div> 
                              <div class="form-group">
                                  <label>Informe seu WhatsApp:</label> 
                                  <input type="text" [(ngModel)]="dataItem.telefone" name="telefone" mask="(99) 99999-9999" placeholder="(**) *****-****" />
                              </div> 
                              <div class="form-group">
                                  <label>Descreva que você está buscando:</label>
                                  <textarea [(ngModel)]="dataItem.mensagem" name="mensagem"></textarea>
                              </div> 
                              <div class="form-group">
                                  <button type="submit">
                                      <span>Enviar</span>
                                      <div class="bg-overlay"></div>
                                  </button>    
                              </div> 
                          </form>
                      </div> 
                  </div>
              </div>
          </div>
      </div> 
      <div class="bg"></div> 
</div>
<loader *ngIf="loader"></loader>