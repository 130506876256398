import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LogoFooterComponent } from 'src/app/modulos/logo-footer/logo-footer.component';
import { PartialModule } from '../../partial/partial.module';

@NgModule({
  declarations: [
    LogoFooterComponent
  ],
  exports: [
    LogoFooterComponent
  ],
  imports: [
    CommonModule,
    PartialModule
  ]
})
export class ModuloLogoFooterModule{}
