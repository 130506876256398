<div class="module module-products">
      <div class="module-title" *ngIf="data.mostrar_subtitulo">
          <div class="container">
              <h2 class="wow slideInUp" data-wow-delay="0.5s">{{data.titulo}}</h2>
              <h3 class="wow slideInDown" data-wow-delay="0.6s">{{data.subtitulo}}</h3>
          </div>    
      </div>
      <div class="module-content wow slideInUp" data-wow-delay="0.3s">
          <div class="container">
              <div class="products slider-items" #slider>  
                  <div class="slider-item" *ngFor="let p of data.produtos">
                        <produto-item [maxText]="60" [data]="p"></produto-item>
                  </div>      
              </div>
              <div class="slide-navigate-item prev" (click)="prev()">
                  <i class="fa-solid fa-chevron-left"></i>
              </div>
              <div class="slide-navigate-item next" (click)="next()">
                  <i class="fa-solid fa-chevron-right"></i>
              </div>  
          </div>
      </div> 
      <div class="center">
          <a (click)="_route('/servicos/passeios')" title="Ver todos os Tours" class="btn-four wow slideInRight">
              <span>Ver mais tours</span>
          </a> 
      </div>
  </div>    
  