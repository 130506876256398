<section class="page-home">  
  <modulo posicao="banner-slider"></modulo>
  <modulo posicao="destaques"></modulo> 
  <modulo posicao="produto-destaque-2"></modulo>
  <modulo posicao="destinos"></modulo> 
  <modulo posicao="depoimento"></modulo>
  <modulo posicao="newsletter"></modulo>
  <modulo posicao="whatsapp"></modulo> 
</section>   

 



