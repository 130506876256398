import { Component, OnInit, Input, AfterViewInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import { NavigationEnd, NavigationStart, Router,Event as NavigationEvent } from '@angular/router';

declare var window:any;

@Component({
  selector: 'menu-partial',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, AfterViewInit {

  @Input("classCustom") classCustom = "";
  @Input("template") template       = "default";
  @ViewChild("content") content:any; 
  @ViewChild("close") close:any; 
  @ViewChild("overlay") overlay:any;   
  public show = false;
  public openMenu = false;


  constructor(
    private router: Router
  ){ }
  

  /**
   * 
   * Open Menu
   * 
   */
  _openMenu(){

    this.openMenu = this.openMenu ? false : true;

  }
  /**
   * 
   * Open
   * 
   */
  _open(){

    if(this.show){

      this.content.nativeElement.classList.remove("slide-right");
      this.content.nativeElement.classList.add("slide-left");

      setTimeout(() => {
        this.content.nativeElement.classList.remove("show");
      },200);
      this.overlay.nativeElement.classList.remove("show");
      this.close.nativeElement.classList.remove("show");
      this.show = false;

    }else{

      this.content.nativeElement.classList.add("slide-right");

      setTimeout(() => {
        this.content.nativeElement.classList.add("show");
      },200);

      this.content.nativeElement.classList.remove("slide-left"); 
      this.overlay.nativeElement.classList.add("show");
      this.close.nativeElement.classList.add("show");
      this.show = true;

    }

  }
  /**
   * 
   * On resize
   * 
   */
   onResize(){

    if(window.innerWidth >= 959){
      this.content.nativeElement.classList.remove("slide-right");
      this.content.nativeElement.classList.remove("slide-left");
    }

  }
  /**
   * 
   * On Events
   * 
   */
  onEvents(){

    this.router.events.forEach((event: NavigationEvent) => {
      
      if(event instanceof NavigationStart){

        if(window.innerWidth <= 959){    

          this.show = false;
          this.content.nativeElement.classList.remove("slide-right");
          this.content.nativeElement.classList.add("slide-left");

          setTimeout(() => {
            this.content.nativeElement.classList.remove("show");
            this.overlay.nativeElement.classList.remove("show");
            this.close.nativeElement.classList.remove("show");
          },200);
        
        }

      }
      if(event instanceof NavigationEnd) {

                
      }

    });


  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{
    this.onResize();
    this.onEvents();
  }
  /** after view */
  ngAfterViewInit(): void {}

}
