import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';
import { ValidatorService } from 'src/app/services/validator.service';

@Component({
  selector: 'modulo-atendimento-personalizado',
  templateUrl: './atendimento-personalizado.component.html',
  styleUrls: ['./atendimento-personalizado.component.scss']
})
export class AtendimentoPersonalizadoComponent implements OnInit {

  @Input("data") data:any         = null;
  @Input("template") template:any = null;
  public dataItem:any = {};
  public loader = false;

  constructor(
    private app: AppService,
    private api: ApiService,
    private dialog: MatDialog,
    private validator: ValidatorService,
    private recaptchaV3Service: ReCaptchaV3Service
  ){}

  /**
   * 
   * Init
   * 
   */
  send(){

    try{

      this.validator.clear();
      this.validator.isEmpty(this.dataItem.nome,"nome","- Informe o seu Nome.");
      this.validator.isEmpty(this.dataItem.email,"email","- Informe o seu E-mail.");
      this.validator.isEmail(this.dataItem.email,"email","- Informe um e-mail válido.");
      this.validator.isEmpty(this.dataItem.telefone,"telefone","- Informe o seu WhatsApp.");
      this.validator.isEmpty(this.dataItem.mensagem,"mensagem","- Escreva o que você está buscando.");

      if(!this.validator.passes()){

        let error = this.validator.getMessagesHtml();

        this.app.info(error);
        return false;

      }

      this.loader = true;

      this.recaptchaV3Service.execute('importantAction')
        .subscribe((token) => {
          
          this.dataItem.token = token;

          this.api.contato().send(this.dataItem).subscribe(response => {

            this.loader = false;

            switch(response.status){
              case 1:
                
                this.app.info("Recebemos o seu contato. Em breve retornaremos.");
                this.initDataItem();
                
              break;
              case 0:
                
                let errors = this.app.getErrors([response.data]);
                this.app.info(errors);

              break;
              default:
                this.app.infoError(response.data);
              break;
            }

          },(response) => {

            this.loader           = false;
            let error = this.api.formatError(response);

            if(error.status == 422){
              this.app.info(error.message); 
            }else{
              this.app.infoError(error.message);  
            }
        

          });

      },(response) => { 

        this.loader   = false;
        this.app.infoError("Token de validação inválido. Recarregue a página para continuar.");
  
      });

    }catch(e){

      this.app.infoError(e.message); 

    }

  }
  /**
   * 
   * Get img
   * 
   */
  getImg(){

    return "/images/modulo/atendimento-personalizado/"+this.data.params.imagem;

  }
  /**
   * 
   * Init data item
   * 
   */
  initDataItem(){

    this.dataItem = {
      nome: "",
      email: "",
      telefone: "",
      mensagem: "",
      apelido: "atendimento-personalizado",
      tipo: 3
    }

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{
    this.initDataItem();
  }


}
