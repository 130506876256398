<div class="module module-products">
      <div class="module-title" *ngIf="data.mostrar_titulo">
          <div class="container">
              <h2 class="wow slideInUp" data-wow-delay="0.5s">{{data.titulo}}</h2>
              <h3 class="wow slideInDown" data-wow-delay="0.6s">{{data.subtitulo}}</h3>
          </div>    
      </div>
      <div class="module-content">
          <div class="container"> 
              <div class="products slider-items" #slider>   
                  <div class="slider-item" *ngFor="let p of data.produtos">
                      <produto-item [data]="p"></produto-item>
                  </div>      
              </div>
              <div class="slide-navigate-item prev" (click)="prev()">
                  <i class="fa-solid fa-chevron-left"></i>
              </div>
              <div class="slide-navigate-item next" (click)="next()">
                  <i class="fa-solid fa-chevron-right"></i>
              </div>  
          </div>
      </div>
      <div class="center">
          <a (click)="_route('/servicos')" title="Passeios" class="btn-one btn-icon wow fadeIn" data-wow-delay="0.2s">
              <span>Ver mais experiências</span>
          </a> 
      </div>     
</div>         