import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { AppService } from '../../services/app.service';
import { DataService } from '../../services/data.service';
import { GtagService } from '../../services/gtag.service';
import { FbService } from '../../services/fb.service';
import { ApiService } from 'src/app/services/api.service';

declare var window:any;
declare var gtag:any;

@Component({
  selector: 'home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePage implements OnInit, AfterViewInit, OnDestroy {
  
  public isSendAnalytics = false; 

  constructor( 
    private app:AppService,
    private data: DataService,
    private gtag: GtagService,
    private fb: FbService 
  ){ }
  


  setMetaTags(){

    let config      = this.data.getConfiguracao();
     let description = config.meta_description;
    
    this.app.setMetaDados(null,description);

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit() {
    
    this.app.toTop();
    this.setMetaTags(); 
    
  }
  /**
   * 
   * Send analytics
   * 
   */
  sendAnalytics(){

    if(!this.isSendAnalytics){
      
      this.isSendAnalytics = true;
      setTimeout(() => {
      
        this.gtag.sendPage();
        this.fb.init(); 
        this.fb.leadSingle();
              
      },100);

    }

  } 
  /***
   * 
   * After view
   * 
   */
  ngAfterViewInit(): void {
    this.app.toTop();  
  }
  /**
   * 
   * On Destroy
   * 
   */
  ngOnDestroy(): void {
    
  

  }

}
