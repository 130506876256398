<div class="product-item {{classCustom}}">
  <div class="capa" *ngIf="produto.foto != null" (click)="_click()">  
    <img *ngIf="isLazy == true || isLazy == 'true'" itemprop="url" width="300px" height="200px" defaultImage="/images/sem-foto/default.jpg" [lazyLoad]="produto.foto" itemprop="image" [alt]="produto.nome" width="100%" /> 
    <img *ngIf="isLazy == false || isLazy == 'false'" itemprop="url" width="300px" height="200px" [src]="produto.foto" itemprop="image" [alt]="produto.nome" width="100%" /> 
  </div>  
  <div class="capa" *ngIf="produto.foto == null" (click)="_click()">
    <img itemprop="url" width="300px" height="200px" itemprop="image" src="/images/sem-foto/default.jpg" alt="Sem Foto" width="100%" />
  </div> 
  <div class="content">
      <h4>{{produto.nome}}</h4>
      <div class="info">
          <div class="item item-tipo" *ngIf="produto.tipo != null">
            <strong>{{produto.tipo}}</strong>
          </div>
          <div class="item item-trajeto" *ngIf="produto.trajeto != null">
            <div class="icon">
              <i class="fa-solid fa-map-location-dot"></i>
            </div>
            <strong>{{produto.trajeto}}</strong>
          </div>
          <div class="item" *ngIf="produto.duracao != null">
              <div class="icon">
                  <i class="fa-solid fa-clock"></i>
              </div>
              <span>Duração Aprox. {{produto.duracao | formatDuracao}}</span>
          </div>
          <div class="item" *ngIf="produto.dias.length > 0">
            <div class="icon">
                <i class="fa-solid fa-calendar-day"></i>
            </div>
            <span>{{produto.dias | formatDias}}</span>
          </div>    
      </div>       
      <div class="footer"> 
        <div class="box">
          <div class="content-valor">
            <div class="tariff" *ngIf="!produto.promocao">
              <span class="text">a partir de: </span>
              <strong class="value" *ngIf="produto.menor_tarifa > 0">
                <span itemprop="price" class="cifrao" itemprop="priceCurrency">€ </span> 
                {{produto.menor_tarifa | currency: ' '}}  
              </strong> 
              <strong class="value" *ngIf="produto.menor_tarifa == 0">
                CONSULTE!
              </strong>
            </div>
            <div class="tariff tariff-promocao" *ngIf="produto.promocao">
              <div class="valor-promocao"> 
                <span>De <strong>€ {{produto.menor_tarifa | currency: ' '}}</strong></span>
              </div>
              <strong class="value">
                <span>Por </span>
                <span class="cifrao" itemprop="priceCurrency">€ </span> 
                <span itemprop="price" class="number">{{produto.promocao_valor | currency: ' '}}</span> 
              </strong>
            </div>  
          </div>  
        </div>
      </div> 
      <!--<div class="installments" *ngIf="produto.mostrar_parcelas">Parcele em até <strong>{{produto.parcelas}} vezes</strong></div>-->
  </div>  
</div>    

  