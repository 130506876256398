<div class="modulo banner-slider" (window:resize)="onResize()">
  <div #banner class="banners slider-itens">
    <div *ngFor="let i of data.banners;let y = index">
      <div class="item slider-item" *ngIf="!i.link_ativo">
        <img *ngIf="width <= 700" width="100%" height="auto" [src]="i.imagem_mobile" [alt]="i.titulo"  class="img-fluid" />
        <img *ngIf="width > 700" width="100%" height="auto" [src]="i.imagem" [alt]="i.titulo"  class="img-fluid" />
      </div>  
      <div *ngIf="i.link_ativo" [ngSwitch]="i.link_tipo">
        <a [routerLink]="getLinkCategoria(i)" *ngSwitchCase="'3'">
          <div class="item slider-item">
            <img *ngIf="width <= 700" width="100%" height="auto" [src]="i.imagem_mobile" [alt]="i.titulo"  class="img-fluid" />
            <img *ngIf="width > 700" width="100%" height="auto" [src]="i.imagem" [alt]="i.titulo"  class="img-fluid" />
          </div>  
        </a>
        <a [routerLink]="getLinkProduto(i)" *ngSwitchCase="'2'">
          <div class="item slider-item">
            <img *ngIf="width <= 700" width="100%" height="auto" [src]="i.imagem_mobile" [alt]="i.titulo"  class="img-fluid" />
            <img *ngIf="width > 700" width="100%" height="auto" [src]="i.imagem" [alt]="i.titulo"  class="img-fluid" />
          </div>   
        </a> 
        <a [href]="i.link" [target]="i.link_target" *ngSwitchCase="'1'">
          <div class="item slider-item">
            <img *ngIf="width <= 700" width="100%" height="auto" [src]="i.imagem_mobile" [alt]="i.titulo"  class="img-fluid" />
            <img *ngIf="width > 700" width="100%" height="auto" [src]="i.imagem" [alt]="i.titulo"  class="img-fluid" />
          </div>  
        </a>   
      </div>
    </div>
  </div>
  <div class="navigate">
    <div class="navigate-item prev" (click)="prevSlide()">
        <div class="content">
            <i class="fa-solid fa-angle-left"></i>
        </div>
    </div> 
    <div class="navigate-item next" (click)="nextSlide()">
        <div class="content">
            <i class="fa-solid fa-angle-right"></i>
        </div>
    </div>    
  </div>  
</div>


