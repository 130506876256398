<div [ngSwitch]="template" itemscope itemtype="https://schema.org/Organization">
  <div class="modulo modulo-endereco" *ngSwitchCase="null">
    <div class="modulo-title" *ngIf="data.mostrar_titulo">
      <h3>{{data.titulo}}</h3>
    </div> 
    <div class="modulo-content"> 
      <div class="icon">
        <i class="material-icons">
          location_on
        </i>
      </div>  
      <article class="endereco" itemprop="address" itemscope itemtype="http://schema.org/PostalAddress">
        <span itemprop="streetAddress">{{data.params.rua}}, nº {{data.params.numero}} | {{data.params.bairro}}</span><br>
        <span itemprop="addressLocality">{{data.params.cidade}}</span><br><span itemprop="postalCode">Cep: {{data.params.cep}}</span>
      </article>  
    </div>  
  </div>
  <div class="modulo modulo-endereco-footer col-endereco" *ngSwitchCase="'footer'">
    <div class="address">
      <div class="icon">
          <i class="fa-sharp fa-solid fa-location-dot"></i>
      </div>
      <div class="text">
          <span>{{data.params.rua}}, {{data.params.numero}} - {{data.params.bairro}}, {{data.params.cidade}}, {{data.params.cep}}</span>
      </div>
    </div> 
    <div class="map radius-iframe" [innerHtml]="data.params.mapa | safeHtml"></div>
  </div>
</div>
