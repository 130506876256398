import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

declare var $:any;

@Component({
  selector: 'produto-item-slider',
  templateUrl: './produto-item-slider.component.html',
  styleUrls: ['./produto-item-slider.component.scss']
})
export class ProdutoItemSliderComponent implements OnInit,AfterViewInit {

  @Input("produtos") produtos:any = [];   
  @ViewChild("slider") slider:ElementRef;        

  /***
  * 
  * Inicializa o Slider
  * 
  ***/
  initSlider(){

    $(this.slider.nativeElement).slick({ 
      infinite: true,
      slidesToShow: 4,
      dots: false, 
      arrows: false,
      centerMode: false,
      autoplay: false,
      autoplaySpeed: 3000,
      centerPadding: '0',
      responsive: [{
          breakpoint: 1224,
          settings: {
            slidesToShow: 4,  
          }
      },{
          breakpoint: 1024,
          settings: {
          slidesToShow: 2,
        }
      },
      {
          breakpoint: 768,
          settings: {
              slidesToShow: 2,

          }
      },
      {
          breakpoint: 540,
          settings: {
            slidesToShow: 1,
            centerPadding: '0',
          }
      }
    ]
    
    }); 
    $(this.slider.nativeElement).slick('refresh'); 
    

  }
  /**
   * 
   * Prev
   * 
   */
  prev(){

    $(this.slider.nativeElement).slick("slickPrev");

  }
  /**
   * 
   * Next
   * 
   */
  next(){

    $(this.slider.nativeElement).slick("slickNext"); 

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){


  }
  ngAfterViewInit(): void {
    this.initSlider();
  }
  ngOnChanges(changes:any): void {}

}
