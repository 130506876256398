<div class="module module-products" *ngIf="data.categorias.length > 0">
      <div class="item" *ngFor="let d of data.categorias;">  
            <div class="module-title">
                  <div class="container">
                        <h2 class="wow slideInUp" data-wow-delay="0.5s">{{d.titulo}}</h2>
                        <h3 class="wow slideInDown" data-wow-delay="0.6s">{{d.subtitulo}}</h3>
                  </div>    
            </div>
            <div class="module-content" *ngIf="d.produtos.length > 0">
                  <div class="container">  
                        <produto-item-slider [produtos]="d.produtos"></produto-item-slider>
                  </div>
            </div>
            <div class="module-content" *ngIf="d.produtos.length == 0">
                  <div class="container"> 
                        <p class="empty">Não há serviços para mostrar nessa categoria.</p>
                  </div>
            </div>
            <div class="center">
                  <a routerLink="/servicos/{{d.apelido}}" [title]="d.titulo" class="btn-two btn-icon wow fadeIn" data-wow-delay="0.2s" (click)="toRoute(d.apelido)">
                      <span>{{d.texto_botao}}</span>
                  </a> 
            </div> 
      </div>
</div>    