<div class="module module-depoimento">
      <div class="module-title" *ngIf="data.mostrar_titulo">
          <div class="container">
              <h2>{{data.titulo}}</h2>
          </div>
      </div>
      <div class="module-content">
          <div class="container">
            <div class="products slider-items" #slider> 
                <div class="item slider-item" *ngFor="let i of data.depoimentos">
                    <div class="capa">
                        <img [src]="i.foto" [alt]="i.foto" width="100px" />
                    </div>
                    <h3>{{i.nome}}</h3>
                    <div class="instagram">
                        <a [href]="i.instagram_link" target="_blank">
                            <strong>{{i.instagram_conta}}</strong>
                        </a>
                    </div> 
                    <article [innerHtml]="i.texto"></article>
                </div>
            </div>
            <div class="slide-navigate-item prev" (click)="prev()">
                <img src="/images/icons/prev.svg" alt="Prev" width="30px" />
            </div>
            <div class="slide-navigate-item next" (click)="next()">
                <img src="/images/icons/next.svg" alt="Next" width="30px" />
            </div> 
          </div>
      </div>
  </div>