<div class="menu-action-partial">
    <ul>
        <!--<li>
            <div class="item" (click)="toCart()">
                <div class="icon tooltip">
                    <i class="fa-sharp fa-solid fa-bag-shopping"></i>
                    <span class="tooltiptext tooltip-bottom">Minhas Compras</span>
                </div>
            </div>    
        </li>-->
        <li>
            <div class="item">
                <div class="icon tooltip" (click)="toFavoritos()">
                    <i class="fa-solid fa-heart"></i>
                    <span class="tooltiptext tooltip-bottom">Meus Favoritos</span>
                </div>
            </div>    
        </li>  
        <li>
            <div class="item">
                <div class="icon tooltip" (click)="toMinhaConta()">
                    <i class="fa-solid fa-user"></i>
                    <span class="tooltiptext tooltip-bottom">Minha Conta</span>
                </div>
            </div>    
        </li>
    </ul>
</div> 
    