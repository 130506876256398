import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'destino-item',
  templateUrl: './destino-item.component.html',
  styleUrls: ['./destino-item.component.scss']
})
export class DestinoItemComponent implements OnInit {

  @Input("data") data:any = {};

  constructor(
    private route: Router
  ) { }

  /**
   * 
   * Clic
   * 
   */
  _click(){

    this.route.navigateByUrl("/destinos/"+this.data.apelido); 
    return false;

  }
  /**
   * 
   * 
   * 
   * @param foto 
   * 
   */
   formatImage(foto){     

    return foto == null ? "/images/destino/sem-foto.jpg" : foto.replace(/ /g,'%20');

  }
  /***
   * 
   * Init
   * 
   */
  ngOnInit():void{}

}
