import { Component, Input, OnInit, ViewChild } from '@angular/core';

declare var $:any;

@Component({
  selector: 'modulo-destinos',
  templateUrl: './modulo-destinos.component.html',
  styleUrls: ['./modulo-destinos.component.scss']
})
export class ModuloDestinosComponent implements OnInit {

  @Input("data") data:any = {};
  @Input("classCustom") classCustom = "";
  @Input("template") template = "";
  @ViewChild("slider") slider:any = null;

  constructor() { }  

   /***
   * 
   * Inicializa o Slider
   * 
   */
    initSlider(){

      $(this.slider.nativeElement).slick({ 
        infinite: true,
        slidesToShow: 4,
        dots: false, 
        arrows: false,
        centerMode: false,
        autoplay: true,
        autoplaySpeed: 2500,
        centerPadding: '0',
        responsive: [{
            breakpoint: 1224,
            settings: {
              slidesToShow: 4,  
            }
        },{
            breakpoint: 1024,
            settings: {
            slidesToShow: 2,
          }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
  
            }
        },
        {
            breakpoint: 540,
            settings: {
              slidesToShow: 1,
              centerPadding: '0',
            }
        }
      ]
      
      }); 
      $(this.slider.nativeElement).slick('refresh'); 
      
  
    }
    /**
     * 
     * Next
     * 
     */
    prev(){ 
  
      $(this.slider.nativeElement).slick("slickPrev");  
  
    }
    /**
     * 
     * Next
     * 
     */
    next(){
  
      $(this.slider.nativeElement).slick("slickNext"); 
  
    }
    /**
     * 
     * Set data
     * 
     */
    ngOnInit():void{}
    /**
     * 
     * After view
     * 
     */
    ngAfterViewInit(): void {
      this.initSlider();
    }

}
