<div class="breadcrumbs">
  <ul>
    <li *ngFor="let item of listItens;let last=last;">
        <a *ngIf="!last && item.routerLink"  [routerLink]="item.routerLink" [title]="item.text" (click)="toRoute(item.routerLink)">
          {{item.text}}
        </a>
        <a *ngIf="last">{{item.text}}</a>
        <span class="icon" *ngIf="!last"><i class="fa-solid fa-arrow-right"></i></span>
    </li>
  </ul>    
</div>     