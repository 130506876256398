import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'produto-destaque-por-categoria',
  templateUrl: './produto-destaque-por-categoria.component.html',
  styleUrls: ['./produto-destaque-por-categoria.component.scss']
})
export class ProdutoDestaquePorCategoriaComponent implements OnInit {

  @Input("data") data;   
  @Input("classCustom") classCustom = ""; 
  @Input("template") template       = null;  
  public produtos                   = [];
  public categorias                 = [];
  public produtosArray              = new Array();
  @ViewChild("slider") slider:ElementRef;
  
  constructor(
    private router:Router
  ){} 

  /**
   * 
   * Route
   * 
   */
  toRoute(categoria){

    let link = "/servicos/"+categoria;  

    this.router.navigateByUrl(link);

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit(): void {} 


}
