<div class="menu-partial {{classCustom}} template-{{template}}" (window:resize)="onResize()">
    <button type="button" class="btn-open" (click)="_open()">
        <i class="fa-solid fa-bars"></i>
    </button>  
    <div class="close" #close (click)="_open()">  
        <i class="fa-solid fa-xmark"></i>
    </div>    
    <div class="content" #content> 
        <div class="content-logo">
            <img src="images/logos/logo.png" alt="Goianos na Ilha da Madeira" width="100px" />
        </div>
        <ul>
            <li>
                <a routerLink="" title="Home">
                    <span>Home</span>
                </a>    
            </li>
            <li>
                <a routerLink="/quem-somos" title="Quem Somos - Goianos na Ilha da Madeira">
                    <span>Quem Somos</span>
                </a>    
            </li>
            <li>
                <a routerLink="/servicos" title="Passeios na Ilha da Madeira">
                    <span>Passeios</span>
                </a>    
            </li>
            <li>
                <a routerLink="/blog" title="Blog Goianos na Ilha da Madeira">
                    <span>Blog</span>
                </a>    
            </li>
            <li> 
                <a routerLink="/galeria-de-fotos" title="Galeria de Fotos - Goianos na Ilha da Madeira">
                    <span>Galeria de Fotos</span>
                </a>    
            </li>
            <li>
                <a routerLink="/contato" title="Contato">
                    <span>Contato</span>
                </a>    
            </li>
            <li class="menu-box desktop home consultoria">
                <a href="javascript::void();" title="Consultoria">
                    <span>Outros Serviços</span>
                    <i class="fa-solid fa-chevron-down"></i>
                </a> 
                <div class="menu-box-content">
                    <ul>
                        <li>
                            <a routerLink="/outros-servicos/relocation" title="Morar na Ilha da Madeira">
                                <span>Relocation</span>
                            </a>    
                        </li>
                        <li>
                            <a routerLink="/outros-servicos/comprar-ou-vender-imoveis" title="Comprar ou Vender Imóveis">
                                <span>Comprar ou Vender Imóveis</span>
                            </a>    
                        </li>
                    </ul>
                </div>    
            </li>
            <li class="menu-slider mobile home consultoria">
                <a href="javascript::void();" title="Consultoria" (click)="_openMenu()">
                    <span>Outros Serviços</span>
                    <i class="fa-solid fa-chevron-down"></i>
                </a> 
                <div class="menu-slider-content" *ngIf="openMenu">
                    <ul>
                        <li>
                            <a routerLink="/outros-servicos/relocation" title="Morar na Ilha da Madeira">
                                <span>Relocation</span>
                            </a>    
                        </li>
                        <li>
                            <a routerLink="/outros-servicos/comprar-ou-vender-imoveis" title="Comprar ou Vender Imóveis">
                                <span>Comprar ou Vender Imóveis</span>
                            </a>    
                        </li>
                    </ul>
                </div>    
            </li>
        </ul> 
    </div>   
</div> 
<div class="overlay" #overlay></div>   


     
