<header class="header {{classCustomTwo}}" #header (window:resize)="onResize()">
    <div class="content">
        <div class="container">
            <div class="row">
                <div class="col-md-4">
                    <modulo class="content-logo" posicao="logo"></modulo>
                </div>
                <div class="col-md-8 center-flex">
                    <div class="content-menu">
                        <menu-partial [classCustom]="classCustom"></menu-partial>
                    </div>
                </div>
            </div>
        </div>     
    </div>
</header>  


