import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'destaque-item',
  templateUrl: './destaque-item.component.html',
  styleUrls: ['./destaque-item.component.scss']
})
export class DestaqueItemComponent implements OnInit {

  @Input("data") data:any = {};
  public link = null;

  constructor(
    private sanitizer: DomSanitizer
  ) { }

  /***
   * 
   * Format embed
   * 
   */
  formatEmbed(url){

    let id   = url.split("?v=");
    let base = "https://www.youtube.com/embed/"+id[1];

    return base;

  }
  /**
   * 
   * Click
   * 
   */
  _click(){

    if(this.data.link_ativo){

      window.open(this.data.link,this.data.link_target);

    }

  }
  /***
   * 
   * Init
   * 
   ***/
  ngOnInit():void{
    if(this.data.tipo == 2){
      this.link = this.sanitizer.bypassSecurityTrustResourceUrl(this.formatEmbed(this.data.video));
    }
  }
  

}
