<div class="produto-item-slider">
      <div class="produtos slider-itens" #slider> 
            <div class="slider-item item-servicos" *ngFor="let item of produtos"> 
                  <produto-item isLazy="false" [data]="item"></produto-item>    
            </div>       
      </div> 
      <div class="slide-navigate-item prev" (click)="prev()">
            <img src="/images/icons/prev.svg" alt="Anterior" />
      </div>
      <div class="slide-navigate-item next" (click)="next()">
            <img src="/images/icons/next.svg" alt="Próximo" />
      </div>
</div>
