import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DepoimentosComponent } from 'src/app/modulos/depoimentos/depoimentos.component';
import { PartialModule } from '../../partial/partial.module';

@NgModule({
  declarations: [
    DepoimentosComponent
  ],
  exports: [
    DepoimentosComponent
  ],
  imports: [
    CommonModule,
    PartialModule
  ]
})
export class ModuloDepoimentoModule{}
