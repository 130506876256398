<footer>   
    <div class="content">
        <div class="container">
            <div class="row">
                <div class="col-md-2">
                    <h3 class="title-h3">Institucional</h3>
                    <ul class="menu-list">
                        <li>
                            <a routerLink="/quem-somos" title="Quem Somos">
                                Quem Somos
                            </a> 
                        </li>
                        <li>
                            <a routerLink="/politicas-de-cancelamento" title="Políticas de Cancelamento">
                                Políticas de Cancelamento 
                            </a> 
                        </li>
                        <li>
                            <a routerLink="/politicas-de-privacidade" title="Políticas de Privacidade">
                                Políticas de Privacidade
                            </a> 
                        </li>
                    </ul>
                </div>
                <div class="col-md-2">
                    <h3 class="title-h3">Ajuda</h3>
                    <ul class="menu-list">
                        <li>
                            <a routerLink="/contato" title="Contato">
                                Contato
                            </a> 
                        </li>
                        <li>
                            <a routerLink="/perguntas-frequentes" title="Perguntas Frequentes">
                                Perguntas Frequentes
                            </a> 
                        </li>
                    </ul>
                </div> 
                <div class="col-md-4 col-border">
                    <div class="bg-border"></div>
                    <h3 class="title-h3">Atendimento</h3>
                    <modulo posicao="contato" template="footer"></modulo>
                </div>
                <div class="col-md-2">  
                   <h3 class="title-h3">Redes Sociais</h3>
                   <modulo posicao="rede-social"></modulo>
                </div>
                <div class="col-md-2">
                    <modulo posicao="logo_footer"></modulo>
                </div>
            </div>
        </div>  
    </div>  
    <div class="bottom">
        <div class="container">
            <div class="copyright">
                <p>
                    <span>Copyright © 2023 - Todos os direitos reservados</span>
                    <a href="https://digitalmovement.com.br/" target="_blank" title="Digital Movement - Soluções em Tecnologia">
                        By <strong>Digital Movement</strong>
                        <img src="images/logos/logo-dm.svg" alt="Digital Movement" width="20px" height="20px" />
                    </a>
                </p>
            </div> 
        </div>  
    </div>    
</footer>    