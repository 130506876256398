<div class="destination-item" (click)="_click()">
      <div class="content">
          <h4>{{data.nome}}</h4>
          <div class="count" *ngIf="data.produtos > 0">
            <span *ngIf="data.produtos == 1">{{data.produtos}} atividade</span>
            <span *ngIf="data.produtos > 1">{{data.produtos}} atividades</span>
        </div>
      </div>  
      <div class="bg"></div> 
      <img [src]="formatImage(data.foto)" width="100%" [alt]="data.nome" />   
</div>  

