import { Injectable } from '@angular/core';
import { DataService } from './data.service';

declare var gtag:any;

@Injectable()
export class GtagService {

  constructor(
    private db: DataService
  ) { }

  /**
   * 
   * Event Menu
   * 
   * @param label 
   * 
   */
  compraRealizada(){
    
    if(typeof(gtag) != "undefined"){
      gtag('event', 'compra_realizada', {
        'event_category' : 'Compra realizada'
      }); 
    }

  }
  /**
   * 
   * Event Menu
   * 
   * @param label 
   * 
   */
  cadastroSite(){
    
    if(typeof(gtag) != "undefined"){
      gtag('event', 'cadastro_site_criacao', {
        'event_category' : 'Criação de cadastro no site'
      }); 
    }

  }
  /**
   * 
   * Event Menu
   * 
   * @param label 
   * 
   */
  clickFacebook(){
    
    if(typeof(gtag) != "undefined"){
      gtag('event', 'login_facebook', {
        'event_category' : 'Clique no botão do Facebook'
      }); 
    }

  }
  /**
   * 
   * Event Menu
   * 
   * @param label 
   * 
   */
  clickGoogle(){
    
    if(typeof(gtag) != "undefined"){
      gtag('event', 'login_google', {
        'event_category' : 'Clique no botão do Facebook'
      }); 
    }

  }
  /**
   * 
   * Event Menu
   * 
   * @param label 
   * 
   */
  submitContato(){
    
    if(typeof(gtag) != "undefined"){
      gtag('event', 'contato', {
        'event_category' : 'Submit no formulário de Contato'
      }); 
    }

  }
  /**
   * 
   * Event Menu
   * 
   * @param label 
   * 
   */
  submitNewsletter(){
    
    if(typeof(gtag) != "undefined"){
      gtag('event', 'newsletter', {
        'event_category' : 'Submit no formulário de Newsletter'
      }); 
    }

  }
   /**
   * 
   * Event Menu
   * 
   * @param label 
   * 
   */
  clickBotaoLigacao(){
    
    if(typeof(gtag) != "undefined"){
      gtag('event', 'ligacao', {
        'event_category' : 'Clique no botão de ligação'
      }); 
    }

  }
  /**
   * 
   * Event Menu
   * 
   * @param label 
   * 
   */
  clickTrabalheConosco(){
    
    if(typeof(gtag) != "undefined"){
      gtag('event', 'trabalhe-conosco', { 
        'event_category' : 'Submit no formulário de trabalhe conosco'
      }); 
    }

  }
  /**
   * 
   * Event Menu
   * 
   * @param label 
   * 
   */
  clickWhatsApp(){
    
    if(typeof(gtag) != "undefined"){
      gtag('event', 'whatsapp', {
        'event_category' : 'WhatsApp2'
      }); 
    }

  }
  /**
   * 
   * Event Menu
   * 
   * @param label 
   * 
   */
  eventMenu(label){

    if(typeof(gtag) != "undefined"){
      gtag('event', 'menu', {
          'event_category' : 'engagement',
          'event_label' : label
      }); 
    }

  }
   /**
   * 
   * Event Menu
   * 
   * @param label 
   * 
   */
  eventList(produtos,list_name="index"){

    if(typeof(gtag) != "undefined"){
      let itens = this.formatarList(produtos,list_name);
      
      gtag('event','view_item_list',{
        "items": itens
      });
    }

  }
  /**
   * 
   * Event Menu
   * 
   * @param label 
   * 
   */
  addCart(produto){
    
    if(typeof(gtag) != "undefined"){
      gtag('event','add_to_cart',{  
        "items": [{
          "id": produto.id,
          "name": produto.nome,
          "category": produto.categoria,
          "price": produto.valor,
          "quantity": 1 
        }]
      }); 
    }

  }
  /**
   * 
   * Remove item cart
   * 
   * @param produto 
   * 
   * 
   */
  removeCart(produto){

    if(typeof(gtag) != "undefined"){
      gtag('event','remove_from_cart',{  
        "items": [{
          "id": produto.id,
          "name": produto.nome,
          "category": produto.categoria,
          "price": produto.valor,
          "quantity": 1 
        }]
      }); 
    }

  }
  /**
   * 
   * Send Pagamento
   * 
   */
   conversion(total,transaction_id){
    
    if(typeof(gtag) != "undefined"){
      gtag('event', 'conversion', {
        "send_to": "AW-11018245838/HpNrCKmW1oIYEM6t9IUp",
        "transaction_id": transaction_id,
        "affiliation": "Toninho Tour",
        "value": total,
        'currency': 'BRL'
      });
    }

  }
  /**
   * 
   * Send Pagamento
   * 
   */
  sendPagamento(produtos,total,transaction_id){
    
    if(typeof(gtag) != "undefined"){
      gtag('event', 'purchase', {
        "transaction_id": transaction_id,
        "affiliation": "Toninho Tour",
        "value": total,
        "tax": 0, 
        "shipping": 0,
        "items": produtos
      });
    }

  }
  /**
   * 
   * Event Menu
   * 
   * @param label 
   * 
   */
  viewItem(produto,list_name="index"){
    
    try{
      gtag('event','view_item',{
        "items": [{
          "id": produto.id,
          "name": produto.nome, 
          "category": produto.categoria
        }]
      });
    }catch(e){
      //alert(e.message)
    }

  }
  /**
   * 
   * Event Menu
   * 
   * @param label 
   * 
   */
  selectProduct(produto){

    if(typeof(gtag) != "undefined"){
      gtag('event', 'select_content', {
        "content_type": "product",
        "items": [
          {
            "id": produto.id,
            "name": produto.nome,
            "list_name": produto.list_name,
            "category": produto.categoria,
            "list_position": produto.list_position
          }
        ]
      }); 
    }

  }
  /**
   * 
   * Formatar itens para lista
   * 
   */
  formatarList(produtos,list_name="index"){


      let data = [];
      
      for(let index = 0; index < produtos.length; index++) {
        
        data.push({ 
          "id": produtos[index].id,
          "name": produtos[index].nome,  
          "category": typeof(produtos[index].categoria_titulo) != "undefined" ? produtos[index].categoria_titulo : null,
          "list_position": (index+1),
          "list_name": list_name
        })
        
      } 

      return data;
    

  }
  /**
   * 
   * Send Page
   * 
   * @param label 
   * 
   */
  sendPage(){

    if(typeof(gtag) != "undefined"){
      //gtag('event','custom.pageReady'); 
    }

  }
  /**
   * 
   * Send Page
   * 
   * @param label 
   * 
   */
  sendPagePath(path){

    try{
      
      let config = this.db.getConfiguracao();
      
      if(typeof(gtag) != "undefined"){ 
        gtag('config',config.google_analytics_id,{'page_path': path});
      }
    }catch(e){
        console.log(e);
    }

  }

}
