import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'page-banner',
  templateUrl: './page-banner.component.html',
  styleUrls: ['./page-banner.component.scss']
})
export class PageBannerComponent implements OnInit {

  @Input("image") image:any = null;

  constructor(){}  

  /**
   * 
   * Init
   * 
   ***/
  ngOnInit():void{}
}
