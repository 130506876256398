import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'menu-action-partial',
  templateUrl: './menu-action-partial.component.html',
  styleUrls: ['./menu-action-partial.component.scss']
})
export class MenuActionPartialComponent implements OnInit {

  constructor(
    private router: Router,
    private dialog: MatDialog
  ){}

  /**
   * 
   * Open login
   * 
   */
  _openLogin(){

    /*this.dialog.open(
      UsuarioLoginModalComponent,{
        width: "400px"
      }
    );*/

  }
  /**
   * 
   * To Cart
   * 
   */
  toCart(){

    this.router.navigateByUrl("/carrinho");  

  }
  /**
   * 
   * To Cart
   * 
   */
   toFavoritos(){

    this.router.navigateByUrl("/minha-conta/favoritos");

  }
  /**
   * 
   * To Cart
   * 
   */
   toMinhaConta(){

    this.router.navigateByUrl("/minha-conta");

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{}


}
