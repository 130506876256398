import { Pipe, PipeTransform } from '@angular/core';
import { AppService } from '../services/app.service';

@Pipe({
  name: 'filterCustom',
  pure: false
})
export class FilterCustomPipe implements PipeTransform {

  constructor(
    private app: AppService
  ){

  }

  transform(items:any,termo:any):any {
    
    if (!items || !termo || termo === "") {
      return items;
    } ;   
    return items.filter(item => this.app.removerAcentos(item.text ? item.text.toLowerCase(): item.title.toLowerCase()).indexOf(this.app.removerAcentos(termo.toLowerCase())) !== -1);

  }

}
